import React from 'react'
import "./GetAppointment.css"


function GetAppointment (){
    return (
        <div className='get-appointment'>
            <p>Get An Appointment Now!!!</p> 
            <br/> 
            <p id="number">+91 93270-57-640</p>
        </div>
    )
}
export default GetAppointment
