import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
 
 
} from "react-router-dom";

//layouts
import RootLayout from "./layouts/RootLayout";


//pages
import Home from "./pages/Home";
import About from "./pages/about-page/About";
// import Facilities from "./pages/facilities-page/Facilities";
import Gallery from "./pages/mention-page/Mention"

import Fac from "./pages/facilities/Facilities"
import GoToTop from "./components/GotoTop"

import React from 'react';



const router = createBrowserRouter(
  createRoutesFromElements(
  
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="aboutus" element={<About />} />
      <Route path="mention" element={<Gallery />} />
      <Route path="facilities" element={<Fac />} />
      {/* <Route path="facilities" element={<Facilities />} /> */}
      
    </Route>
   
    
   
  )
);

function App() {
  

  return (<div>
    
    <RouterProvider router={router} /> ; 
    
    
  <GoToTop/>
  
  </div>)

}

export default App;
