import React, { useState } from 'react';
import './Mention.css';
import { Helmet } from 'react-helmet';

const Gallery = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageDesc, setSelectedImageDesc] = useState('');

  const openModal = (imageSrc, imageDesc) => {
    setSelectedImage(imageSrc);
    setSelectedImageDesc(imageDesc);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageDesc('');
    setModalVisible(false);
  };

  const imagesWithDescriptions = [
    {
      src: 'image1.jpeg',
      desc: 'Along with MP Mr. Ishwar Parmar from Bardoli',
      
    },
    {
      src: 'image2.jpeg',
      desc: 'Awarded best temporal bone dissector at Bhuvneshvar cochlear implant conference',
    },
    {
        src: 'image3.jpeg',
        desc: 'Cochlear implant training at Pushkar',
      },
      {
        src: 'image4.jpeg',
        desc: 'Dedicated staff of Sudeep hospital',
      }, {
        src: 'image5.jpeg',
        desc: 'Padmashri Mrs. Ramilaben Gamit from Tapi',
      },
      {
        src: 'image6.jpeg',
        desc: 'Navigation & Robotics training at Bangalore',
      },
      {
          src: 'image7.jpeg',
          desc: 'Along with MLA Buhari Mr Mohanbhai Dodia',
        },
        {
          src: 'image8.jpeg',
          desc: 'Our second international fellow in skullbase fellowship started at 2019. Dr. Abdul from Yemen',
        }, {
            src: 'image9.jpeg',
            desc: 'Dr. Seth Neurosurgeon from South Africa for our hospital visit',
          },
          {
            src: 'image10.jpeg',
            desc: 'Along with BJP Leader Mr. Parekh of Mandvi',
          }, {
            src: 'image11.jpeg',
            desc: 'Swamiji from Swaminarayan mandir Sankri',
          },
          {
            src: 'image12.jpeg',
            desc: 'With Dr. T. N. Janakiram Sir conference at Hyderabad',
          }, {
            src: 'image13.jpeg',
            desc: 'Nominated for Best out standing doctor by Lions club',
          },
          {
            src: 'image14.jpeg',
            desc: 'Cochlear Implant conference Bhuvneshwar',
          },{
            src: 'image15.jpeg',
            desc: 'Robotic and navigation training at Dr.Vikas Agrawal sir Mumbai',
          },{
            src: 'image16.jpeg',
            desc: 'Medtronic M5 debrider machine installed first in south guj',
          },{
            src: 'image17.jpeg',
            desc: 'Cochlear implant training with Dr Neeraj Suri madam',
          },
        
    // Add more image objects with descriptions here
  ];

  return (
    <div className="gallery-container">
      <Helmet>
     
     <meta charSet="utf-8" />
     <title>Best ENT Specialist in surat | Sudeep ENT Hospital | Expert Ear, Nose & Throat Care in surat, Gujarat</title>
     <meta name="description" content="Meet Dr. Prayatna Kumar & Dr. Vidhi Gupta, a leading ENT and Cochlear Specialist serving Surat, Navsari, and Bardoli. Learn about Dr. Prayatna Kumar & Dr. Vidhi Gupta's expertise and commitment to providing top-quality ear, nose, and throat care."></meta>
     <meta name="keywords" content="top 5 ent specialist in surat, ent specialist in surat city light, best ent hospital in surat, top 3 et doctirs in surat,best ent dr near me"></meta>
     <meta name="author" content="Sudeep ENT Hospital"></meta>
     <link rel="canonical" href="https://sudeepenthospital.com/mention" />
     <meta name="robots" content="index, follow" />
</Helmet>
        <div className="mentions-heading">
            PHOTO GALLERY
        </div>
      <div className="gallery">
        
        {imagesWithDescriptions.map((pic, index) => (
          <div key={index} className="gallery-item">
            <img
              src={pic.src}
              alt={` ${index + 1}`}
              className="gallery-image"
              onClick={() => openModal(pic.src, pic.desc)}
            />
            <div className="image-description">{pic.desc}</div>
          </div>
        ))}
      </div>

      {modalVisible && (
        <div className="modal">
          <span className="close-modal" onClick={closeModal}>
            &times;
          </span>
          <img src={selectedImage} alt="Popup " className="modal-image" />
          <div className="modal-description">{selectedImageDesc}</div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
