import './footer.css';

const Footer = () => {
  return (
    <div>
        <div className="footer_text">
         <div>© 2023 sudeep-ENT. All Rights Reserved </div>
        {/* <div>| Designed and Developed with ❤️ by <b><a href="https://devine-techknowlogy.netlify.app/"  rel="noopener noreferre">Devine Techknowlogy</a></b></div> */}
        </div>
        
    </div>
  )
}

export default Footer